import { useState, useEffect } from 'react';
import { Button, Card, Modal } from 'bv-components';
import classnames from 'classnames';
import { setFragment, replaceFragment } from 'bv-helpers/location';
import { getCookie, setCookie, removeCookie } from 'bv';
import { t } from 'bv-i18n';
import { REDIRECTION_TARGET } from 'Questionnaire/constants';
import PageOption from './landing_page_option';
import apiSubmitCustomerState from '../api';

const types = {
  ok: 'OK',
  moreInfo: 'MOREINFO',
  assessment: 'ASSESSMENT',
};

const LandingPage = () => {
  const [selected, setSelected] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const ctaClick = (type) => () => setSelected(type);
  const buttonStyles = classnames(
    'sg-landing-page__button sg-landing-page__submit bvs-button is-primary', {
      disabled: !selected,
    },
  );

  useEffect(() => {
    if (!getCookie('safer_gambling_validation')) {
      setCookie('safer_gambling_validation', true);
    }

    // Add and remove body classes
    document.body.classList.add('safer-gambling-landing-page-view');

    return () => { document.body.classList.remove('safer-gambling-landing-page-view'); };
  }, []);

  const handleClick = () => {
    setSubmitted(true);
    apiSubmitCustomerState(selected)
      .then((response) => {
        if (response.success) {
          removeCookie('safer_gambling_validation');
          if (selected === types.ok) setFragment('/');
          else if (selected === types.moreInfo) replaceFragment('/safer_gambling/portal');
          else {
            const redirectPath = REDIRECTION_TARGET[response.redirect] || '/account/verification_zone';
            replaceFragment(`${redirectPath}?first_modal=true`);
          }
        } else {
          setSubmitted(false);
          setError(true);
        }
      });
  };

  return (
    <>
      <Card className={`sg-landing-page__card animated ${submitted ? 'slideoutdown' : 'slideinup'}`}>
        <div className="sg-landing-page__title">{t('javascript.sg.questionnaire.landing_page.title')}</div>
        <div className="bvs-cards-group">
          <PageOption text="ok_message" type={types.ok} ctaClick={ctaClick} selected={selected} />
          <PageOption text="more_information" type={types.moreInfo} ctaClick={ctaClick} selected={selected} />
          <PageOption text="need_an_assessment" type={types.assessment} ctaClick={ctaClick} selected={selected} />
        </div>
        <Button
          label={t('javascript.sg.questionnaire.submit')}
          type="submit"
          className={buttonStyles}
          onClick={handleClick}
        />
      </Card>
      {error && (
        <Modal iconClosable danger onCloseClick={() => setError(false)}>
          <p>{t('javascript.errors_something_wrong')}</p>
        </Modal>
      )}
    </>
  );
};

export default LandingPage;
