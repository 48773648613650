import PropTypes from 'prop-types';
import classnames from 'classnames';
import { t } from 'bv-i18n';

const LandingPageOption = ({
  text, type, selected, ctaClick,
}) => {
  const styles = classnames('bvs-card', {
    active: selected === type,
  });

  return (
    <div
      className={styles}
      onClick={ctaClick(type)}
    >
      {t(`javascript.sg.questionnaire.cta.${text}`)}
    </div>
  );
};

LandingPageOption.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  selected: PropTypes.string,
  ctaClick: PropTypes.func.isRequired,
};

LandingPageOption.defaultProps = {
  selected: null,
};

export default LandingPageOption;
